@import "main.css";
#navigation-bar-show .dropdown {
    display: none;
}


nav#navigation-bar-hide li.dropdown {
    display: none;
}

.first-activity-div,
.second-activity-div,
.third-activity-div {
    color: white !important; /* Force tout le texte à être blanc */
}

/*----------------------------------------  SVG horaires de la bonne couleur*/
#Calque_2 {
  fill: #13378d;
}

#Calque_1 {
  fill: #13378d;
}

#Calque_3 {
    fill: #13378d;
  }